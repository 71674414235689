(function () {
  'use strict';

  const selectors = {
    performance: '.js-performance',
    details: '.js-details',
    day: '.js-day',
    room: '.js-rooms',
    roomButton: '.js-room-button',
    body: 'body'
  };

  const setRoomCols = () => {
    const days = document.querySelectorAll(selectors.day);
    const body = document.querySelector(selectors.body);

    days.forEach(day => {
      if (day instanceof HTMLElement) {
        const setCols = day.dataset.cols;
        const cols = body.clientWidth < 768 ? 1 : setCols;
        day.style.setProperty('--room-cols', cols);
      }
    });
  };

  const changeRoom = e => {
    const target = e.target;
    const rooms = document.querySelectorAll(selectors.room);
    const roomsId = target.dataset.room;
    rooms.forEach(room => {
      if (room instanceof HTMLElement) {
        room.classList.remove('day__rooms--active');
        const roomData = room.dataset.room;
        if (roomsId === roomData) {
          console.log(roomData, roomsId);
          room.classList.add('day__rooms--active');
        }
        // const activeRoom = document.querySelector(`[data-room="${room}"]`);
        // if (room instanceof HTMLElement) {
        //   activeRoom.classList.add('day__room--active');
        // }
      }
    });
  };

  const init = () => {
    const performances = document.querySelectorAll(selectors.performance);
    const body = document.querySelector(selectors.body);
    const roomButtons = document.querySelectorAll(selectors.roomButton);

    if (!performances.length) {
      return;
    }
    roomButtons.forEach(roomButton => {
      if (roomButton instanceof HTMLElement) {
        roomButton.addEventListener('click', e => changeRoom(e));
      }
    });

    performances.forEach(performance => {
      if (performance instanceof HTMLElement) {
        performance.addEventListener('click', e => {
          const target = e.target;
          const details = performance.querySelector(selectors.details);
          if (details instanceof HTMLElement) {
            details.classList.add('day__room-detail--active');
            body.classList.add('overflow-hidden');
          }

          if (target.classList.contains('js-close') || target.classList.contains('day__room-detail__wrapper')) {
            details.classList.remove('day__room-detail--active');
            body.classList.remove('overflow-hidden');
          }
        });
      }
    });

    setRoomCols();
  };

  document.addEventListener('DOMContentLoaded', init);
  window.addEventListener('resize', setRoomCols);
})();
